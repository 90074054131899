const initialState = {
    openNewsletter: false
}

const TOGGLE_NEWSLETTER = 'TOGGLE_NEWSLETTER'

export const toggleNewsletter = openNewsletter => ({
    type: TOGGLE_NEWSLETTER, openNewsletter
})

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NEWSLETTER:
            return { ...state, openNewsletter: action.openNewsletter }
        default:
            return state
    }
}