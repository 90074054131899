exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acordos-e-parcerias-js": () => import("./../../../src/pages/acordos-e-parcerias.js" /* webpackChunkName: "component---src-pages-acordos-e-parcerias-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-seguranca-js": () => import("./../../../src/pages/seguranca.js" /* webpackChunkName: "component---src-pages-seguranca-js" */),
  "component---src-pages-sobrenos-js": () => import("./../../../src/pages/sobrenos.js" /* webpackChunkName: "component---src-pages-sobrenos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-subpage-template-js": () => import("./../../../src/templates/subpageTemplate.js" /* webpackChunkName: "component---src-templates-subpage-template-js" */)
}

